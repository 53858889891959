import { render, staticRenderFns } from "./quienes-somos.vue?vue&type=template&id=448a71d6&scoped=true"
import script from "./quienes-somos.vue?vue&type=script&lang=js"
export * from "./quienes-somos.vue?vue&type=script&lang=js"
import style0 from "./quienes-somos.vue?vue&type=style&index=0&id=448a71d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448a71d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMain: require('/builds/marketing/abstracta.academy-website/components/HeaderMain.vue').default})
