//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BForm, BFormGroup, BFormInvalidFeedback, BFormInput, BFormTextarea, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BSpinner } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueRecaptcha from 'vue-recaptcha';
import { trackLead } from '~/plugins/tracking-variables.js';

export default {
  name: "ContactForm",
  components: { ValidationProvider, ValidationObserver, VueRecaptcha, BForm, BFormGroup, BFormInvalidFeedback, BFormInput, BFormTextarea, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BSpinner },
  data() {
    return {
      form: {
        email: "",
        name: "",
        queryType: [],
        selectedCourse: "",
        message: ""
      },
      courseOptions: [
        { value: "", text: "", disabled: true },
        { value: "Especialización en testing funcional y accesibilidad", text: "Especialización en testing funcional y accesibilidad" },
        { value: "Especialización en automatización de pruebas", text: "Especialización en automatización de pruebas" },
        { value: "Especialización en automatización: Selenium + SoapUI", text: "Especialización en automatización: Selenium + SoapUI" },
        { value: "Especialización en automatización: SoapUI + Katalon", text: "Especialización en automatización: SoapUI + Katalon" },
        { value: "Introducción a Api Testing", text: "Introducción a Api Testing" },
        { value: "Introducción a la automatización", text: "Introducción a la automatización" },
        { value: "Introducción a Postman", text: "Introducción a Postman" },
        { value: "Introducción a SoapUI con Groovy", text: "Introducción a SoapUI con Groovy" },
        { value: "Katalon para API testing", text: "Katalon para API testing" },
        { value: "Katalon para Web testing", text: "Katalon para Web testing" },
        { value: "Mobile testing", text: "Mobile testing" },
        { value: "OrgaNICE! Cómo mejorar la productividad", text: "OrgaNICE! Cómo mejorar la productividad" },
        { value: "Performance testing con JMeter y BlazeMeter", text: "Performance testing con JMeter y BlazeMeter" },
        { value: "Pruebas de accesibilidad web y móvil", text: "Pruebas de accesibilidad web y móvil" },
        { value: "Selenium - Java", text: "Selenium - Java" },
        { value: "Software Tester", text: "Software Tester" },
        { value: "Técnicas de caja blanca", text: "Técnicas de caja blanca" },
        { value: "Técnicas de caja negra", text: "Técnicas de caja negra" },
        { value: "Testing exploratorio", text: "Testing exploratorio" },
      ],
      captchaState: null,
      captchaResponse: null,
      formSubmitting: false,
      formError: ""
    };
  },
  methods: {
    onSubmit: async function () {
      if (this.formSubmitting) return;

      this.formError = "";

      const formValid = await this.$refs.observer.validate();
      if (!this.captchaResponse) this.captchaState = false;
      if (!this.captchaResponse || !formValid) {
        return;
      }

      this.formSubmitting = true;

      try {
        await this.$api.contact.acAddContact({
          name: this.form.name,
          email: this.form.email,
          queryType: this.form.queryType,
          course: this.form.selectedCourse,
          comments: this.form.message,
          captcharesponse: this.captchaResponse,
          form: "121",
          ref: this.$trackingVars.ref(),
          prevsite: this.$trackingVars.prevSite(),
          device: this.$trackingVars.deviceType(),
          url: window.location.href
        });

        // 🔥 Llamamos a `trackLead` para registrar el evento en Facebook
        trackLead(this.form.email, "");

        this.$router.push(this.localePath('/confirmacion-contacto'));
      }
      catch (e) {
        this.formError = "Ha ocurrido un error, por favor vuelve a intentar en unos segundos."
        console.error(e);
      }
      finally {
        this.formSubmitting = false;
      }

    },
    onReset() {
      this.form.email = "";
      this.form.name = "";
      this.form.selectedCourse = null;
      this.form.queryType = [];
      this.form.message = "";

      this.$refs.observer.reset();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onCaptchaVerify(response) {
      this.captchaState = true;
      this.captchaResponse = response;
    },
    onCaptchaError() {
      this.captchaState = false;
      this.captchaResponse = null;
    },
    onCaptchaTimeout() {
      this.captchaState = false;
      this.captchaResponse = null;
    }
  },
  watch: {
    async 'form.queryType'(newVal, oldVal) {
      if (newVal.length == 0) {
        await this.$nextTick();
        this.form.queryType = oldVal;
      }
      else if (newVal.length == 2) {
        await this.$nextTick();
        this.form.queryType = newVal.filter(v => (!oldVal.includes(v)));
      }
    }
  },
};
