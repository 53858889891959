import { render, staticRenderFns } from "./empresas.vue?vue&type=template&id=93bc30e0&scoped=true"
import script from "./empresas.vue?vue&type=script&lang=js"
export * from "./empresas.vue?vue&type=script&lang=js"
import style0 from "./empresas.vue?vue&type=style&index=0&id=93bc30e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93bc30e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMain: require('/builds/marketing/abstracta.academy-website/components/HeaderMain.vue').default})
